<template>
  <section id="login-2">
    <div v-if="!isModal" class="section">
      <div class="columns is-multiline is-centered is-vcentered">
        <div v-show="showLogin" class="column is-one-third">
          <div class="lg-container">
            <div class="columns is-multiline is-mobile">
              <div class="column is-full">
                <div class="centralize">
                  <router-link to="/"
                    ><img
                      src="@/assets/logotipo_green.png"
                      alt="Escala 10"
                      width="110"
                      style="margin-bottom: 30px"
                  /></router-link>
                  <h1 class="title">LOGIN</h1>
                  <p class="subtitle">
                    Use suas credenciais para entrar no site.
                  </p>
                </div>
                <div class="lg-content">
                  <b-field label="Email">
                    <b-input
                      type="email"
                      v-model="auth.email"
                      required
                      @keyup.native.enter="authentication()"
                    >
                    </b-input>
                  </b-field>

                  <b-field label="Senha">
                    <b-input
                      type="password"
                      v-model="auth.pwd"
                      password-reveal
                      required
                      @keyup.native.enter="authentication()"
                    >
                    </b-input>
                  </b-field>

                  <b-field>
                    <b-button
                      size="is-small"
                      icon-pack="far"
                      icon-right="question-circle"
                      type="is-text"
                      @click="openForgotModal()"
                      >Esqueceu a Senha?</b-button
                    >
                  </b-field>

                  <div class="has-text-centered">
                    <p class="subtitle">
                      Em caso de dúvidas, favor entrar em contato com o suporte
                    </p>
                    <p class="title">suporte10@escala10.com.br</p>
                  </div>

                  <b-field style="margin-top: 30px; float: right">
                    <b-button
                      size="is-medium"
                      @keyup.native.enter="authentication()"
                      type="is-primary"
                      @click="authentication()"
                      >Entrar</b-button
                    >
                  </b-field>
                </div>
              </div>

              <div class="column is-full">
                <div class="lg-content light">
                  <p class="subtitle" style="margin-bottom: 10px">
                    Ainda não possui uma conta? Crie agora mesmo
                  </p>
                  <b-field>
                    <b-button
                      size="is-small"
                      type="is-light"
                      v-on:click="showRegisterVodal('register')"
                      outlined
                      >Criar Conta</b-button
                    >
                  </b-field>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-show="showRegister" class="column is-two-fifths">
          <div class="lg-container">
            <div class="columns">
              <div class="column is-full">
                <div class="centralize" style="margin: 20px 0 25px 0">
                  <h1 class="title">CADASTRO</h1>
                  <p class="subtitle" style="text-align: center">
                    Insira suas informações para se cadastrar no Escala10.
                  </p>
                  <b-field>
                    <b-button
                      size="is-small"
                      type="is-light"
                      v-on:click="showRegisterVodal('login')"
                      outlined
                      >Voltar ao Login</b-button
                    >
                  </b-field>
                </div>

                <b-steps v-model="activeStep" size="is-small">
                  <b-step-item label="Sobre" icon="account" type="is-success">
                    <div class="itens-tab">
                      <b-field label="Nome">
                        <b-input
                          ref="nome"
                          v-model="cadUser.nome"
                          @blur="validForm('nome')"
                          maxlength="40"
                          pattern="[A-Za-zÀ-ú\s]{1,40}"
                          required
                          validation-message="40 Caracteres permitidos: A-Z, a-z, À-ú, espaços"
                        ></b-input>
                      </b-field>
                      <b-field label="Sobrenome">
                        <b-input
                          ref="sobrenome"
                          v-model="cadUser.sobrenome"
                          @blur="validForm('sobrenome')"
                          maxlength="80"
                          pattern="[A-Za-zÀ-ú\s]{1,80}"
                          required
                          validation-message="80 Caracteres permitidos: A-Z, a-z, À-ú, espaços"
                        ></b-input>
                      </b-field>
                      <b-field label="CPF">
                        <template slot="label">
                          <p>CPF</p>
                          <span style="font-size: 0.7rem"
                            >(necessário para transações bancárias em
                            Ligas)</span
                          >
                        </template>
                        <b-input
                          ref="cpf"
                          v-model="cadUser.cpf"
                          @blur="validForm('cpf')"
                          maxlength="11"
                          pattern="[0-9]{11}"
                          required
                          validation-message="Apenas números sem formatação com 11 digitos"
                        ></b-input>
                      </b-field>
                      <b-field label="Telefone">
                        <template slot="label">
                          <p>Telefone</p>
                          <span style="font-size: 0.7rem"
                            >(DDD + Número - apenas números sem
                            formatação)</span
                          >
                        </template>
                        <b-input
                          ref="telefone"
                          v-model="cadUser.telefone"
                          @blur="validForm('telefone')"
                          maxlength="11"
                          pattern="[0-9]{11}"
                          icon="whatsapp"
                          required
                          validation-message="Apenas números sem formatação com 11 digitos"
                        ></b-input>
                      </b-field>
                    </div>
                  </b-step-item>

                  <b-step-item
                    label="Credenciais"
                    icon="account-key"
                    type="is-success"
                  >
                    <div class="itens-tab">
                      <b-field label="Email">
                        <b-input
                          ref="email"
                          type="email"
                          v-model="cadUser.email"
                          @input="formatField('cadUser.email')"
                          @blur="validForm('email')"
                          icon="email"
                          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                          required
                        ></b-input>
                      </b-field>
                      <b-field label="Senha">
                        <b-input
                          ref="senha"
                          type="password"
                          v-model="cadUser.pass"
                          password-reveal
                          required
                        ></b-input>
                      </b-field>
                    </div>
                  </b-step-item>

                  <b-step-item
                    label="Localização"
                    icon="account-plus"
                    type="is-info"
                  >
                    <div class="itens-tab">
                      <b-field label="Cidade">
                        <b-input
                          ref="cidade"
                          v-model="cadUser.endereco.cidade"
                          @blur="validForm('cidade')"
                          maxlength="20"
                          pattern="[0-9A-Za-zÀ-ú\s]{1,20}"
                          required
                          validation-message="20 Caracteres permitidos: 0-9, A-Z, a-z, À-ú, espaços"
                        ></b-input>
                      </b-field>
                      <b-field label="Estado">
                        <b-select
                          ref="uf"
                          v-model="cadUser.endereco.uf"
                          placeholder="Selecione seu Estado"
                          expanded
                          required
                        >
                          <option
                            v-for="(estado, i) of states"
                            :key="i"
                            :value="estado"
                          >
                            {{ estado }}
                          </option>
                        </b-select>
                      </b-field>
                      <b-field label="País">
                        <b-input
                          ref="pais"
                          v-model="cadUser.endereco.pais"
                          @blur="validForm('pais')"
                          maxlength="20"
                          pattern="[A-Za-zÀ-ú\s]{1,20}"
                          required
                          validation-message="20 Caracteres permitidos: A-Z, a-z, À-ú, espaços"
                        ></b-input>
                      </b-field>
                    </div>
                    <b-field style="margin-top: 20px; float: right">
                      <b-button
                        type="is-light"
                        @click="showRegisterVodal('login')"
                        outlined
                        style="margin-right: 15px"
                        >Cancelar</b-button
                      >
                      <b-button type="is-primary" @click="validForm()"
                        >Avançar</b-button
                      >
                    </b-field>
                  </b-step-item>
                </b-steps>
              </div>
            </div>
          </div>
        </div>

        <div v-if="showSignature" class="column is-three-fifths">
          <div class="lg-container">
            <div class="level centralize">
              <h1 class="title">Assinar</h1>
              <h2 class="subtitle" style="text-align: center">
                Selecione um plano de assinatura para ter o acesso ao conteúdo
                do nosso site de acordo com os períodos abaixo
              </h2>
              <b-field>
                <b-button
                  size="is-small"
                  type="is-light"
                  v-on:click="showRegisterVodal('login')"
                  outlined
                  >Voltar ao Login</b-button
                >
                <b-button
                  size="is-small"
                  type="is-light"
                  v-on:click="showRegisterVodal('register')"
                  outlined
                  >Voltar ao Cadastro</b-button
                >
              </b-field>
            </div>
            <div class="content">
              <payment
                product="Planos"
                :hasCupom="true"
                :cpf="cadUser.cpf"
                :email="cadUser.email"
                :defaultInfluencer="influencer"
                :defaultCupom="cupom"
              ></payment>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-else
      class="modal-card"
      :style="
        $mq == 'sm'
          ? 'width: auto; min-height: 90vh; max-height: 90vh;'
          : 'width: auto; max-height: 90vh;'
      "
    >
      <header class="modal-card-head">
        <p class="modal-card-title">Faça seu LOGIN ou CADASTRE-SE</p>
      </header>

      <section class="modal-card-body">
        <div class="columns is-multiline is-gapless is-centered is-vcentered">
          <div v-show="showLogin" class="column is-full">
            <div class="lg-container">
              <div class="columns is-multiline is-gapless is-mobile">
                <div class="column is-full">
                  <div class="lg-content">
                    <b-field label="Email">
                      <b-input
                        type="email"
                        v-model="auth.email"
                        required
                        @keyup.native.enter="authentication()"
                      >
                      </b-input>
                    </b-field>

                    <b-field label="Senha">
                      <b-input
                        type="password"
                        v-model="auth.pwd"
                        password-reveal
                        required
                        @keyup.native.enter="authentication()"
                      >
                      </b-input>
                    </b-field>

                    <b-field>
                      <b-button
                        size="is-small"
                        icon-pack="far"
                        icon-right="question-circle"
                        type="is-text"
                        @click="openForgotModal()"
                        >Esqueceu a Senha?</b-button
                      >
                    </b-field>

                    <div class="has-text-centered">
                      <p class="subtitle">
                        Em caso de dúvidas, favor entrar em contato com o
                        suporte
                      </p>
                      <p class="title">suporte10@escala10.com.br</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-show="showRegister" class="column is-full">
            <div class="lg-container">
              <div class="columns is-multiline is-gapless is-mobile">
                <div class="column is-half">
                  <b-field label="Nome">
                    <b-input
                      ref="nome"
                      v-model="cadUser.nome"
                      @blur="validForm('nome')"
                      maxlength="40"
                      pattern="[A-Za-zÀ-ú\s]{1,40}"
                      required
                      validation-message="40 Caracteres permitidos: A-Z, a-z, À-ú, espaços"
                    ></b-input>
                  </b-field>
                </div>
                <div class="column is-half">
                  <b-field label="Sobrenome">
                    <b-input
                      ref="sobrenome"
                      v-model="cadUser.sobrenome"
                      @blur="validForm('sobrenome')"
                      maxlength="80"
                      pattern="[A-Za-zÀ-ú\s]{1,80}"
                      required
                      validation-message="80 Caracteres permitidos: A-Z, a-z, À-ú, espaços"
                    ></b-input>
                  </b-field>
                </div>
                <div class="column is-half">
                  <b-field label="CPF">
                    <template slot="label">
                      <p>CPF</p>
                      <span style="font-size: 0.7rem"
                        >(necessário para transações bancárias em Ligas)</span
                      >
                    </template>
                    <b-input
                      ref="cpf"
                      v-model="cadUser.cpf"
                      @blur="validForm('cpf')"
                      maxlength="11"
                      pattern="[0-9]{11}"
                      required
                      validation-message="Apenas números sem formatação com 11 digitos"
                    ></b-input>
                  </b-field>
                </div>
                <div class="column is-half">
                  <b-field label="Telefone">
                    <template slot="label">
                      <p>Telefone</p>
                      <span style="font-size: 0.7rem">(DDD + Número)</span>
                    </template>
                    <b-input
                      ref="telefone"
                      v-model="cadUser.telefone"
                      @blur="validForm('telefone')"
                      maxlength="11"
                      pattern="[0-9]{11}"
                      icon="whatsapp"
                      required
                      validation-message="Apenas números sem formatação com 11 digitos"
                    ></b-input>
                  </b-field>
                </div>
                <div class="column is-full">
                  <b-field label="Email">
                    <b-input
                      ref="email"
                      type="email"
                      v-model="cadUser.email"
                      @input="formatField('cadUser.email')"
                      @blur="validForm('email')"
                      icon="email"
                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                      required
                    ></b-input>
                  </b-field>
                  <b-field label="Senha">
                    <b-input
                      ref="senha"
                      type="password"
                      v-model="cadUser.pass"
                      password-reveal
                      required
                    ></b-input>
                  </b-field>
                </div>
                <div class="column is-half">
                  <b-field label="Cidade">
                    <b-input
                      ref="cidade"
                      v-model="cadUser.endereco.cidade"
                      @blur="validForm('cidade')"
                      maxlength="20"
                      pattern="[0-9A-Za-zÀ-ú\s]{1,20}"
                      required
                      validation-message="20 Caracteres permitidos: 0-9, A-Z, a-z, À-ú, espaços"
                    ></b-input>
                  </b-field>
                </div>
                <div class="column is-half">
                  <b-field label="Estado">
                    <b-select
                      ref="uf"
                      v-model="cadUser.endereco.uf"
                      placeholder="Selecione seu Estado"
                      expanded
                      required
                    >
                      <option
                        v-for="(estado, i) of states"
                        :key="i"
                        :value="estado"
                      >
                        {{ estado }}
                      </option>
                    </b-select>
                  </b-field>
                </div>
                <div class="column is-full">
                  <b-field label="País">
                    <b-input
                      ref="pais"
                      v-model="cadUser.endereco.pais"
                      @blur="validForm('pais')"
                      maxlength="20"
                      pattern="[A-Za-zÀ-ú\s]{1,20}"
                      required
                      validation-message="20 Caracteres permitidos: A-Z, a-z, À-ú, espaços"
                    ></b-input>
                  </b-field>
                </div>
              </div>
            </div>
          </div>

          <div v-if="showSignature" class="column is-full">
            <div class="lg-container">
              <div class="level centralize">
                <h1 class="title">Assinar</h1>
                <h2 class="subtitle" style="text-align: center">
                  Selecione um plano de assinatura para ter o acesso ao conteúdo
                  do nosso site de acordo com os períodos abaixo
                </h2>
              </div>
              <div class="content">
                <payment
                  product="Planos"
                  :hasCupom="true"
                  :cpf="cadUser.cpf"
                  :email="cadUser.email"
                  :defaultInfluencer="influencer"
                  :defaultCupom="cupom"
                ></payment>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer class="modal-card-foot">
        <div
          class="columns is-mobile is-centered is-gapless"
          style="width: 100%"
        >
          <div
            class="column is-narrow"
            style="position: relative; align-self: center"
          >
            <b-button
              :size="$mq == 'sm' ? 'is-small' : ''"
              type="is-danger"
              @click="$parent.close()"
              outlined
              >Cancelar</b-button
            >
            <b-button
              :size="$mq == 'sm' ? 'is-small' : ''"
              v-show="showLogin"
              @keyup.native.enter="authentication()"
              type="is-primary"
              @click="authentication()"
              >Fazer Login</b-button
            >
            <b-button
              :size="$mq == 'sm' ? 'is-small' : ''"
              v-show="showLogin"
              type="is-light"
              v-on:click="showRegisterVodal('register')"
              outlined
              >Criar Conta</b-button
            >
            <b-button
              :size="$mq == 'sm' ? 'is-small' : ''"
              v-show="showSignature"
              type="is-light"
              v-on:click="showRegisterVodal('register')"
              outlined
              >Voltar ao Cadastro</b-button
            >
            <b-button
              :size="$mq == 'sm' ? 'is-small' : ''"
              v-show="showRegister || showSignature"
              type="is-light"
              v-on:click="showRegisterVodal('login')"
              outlined
              >Voltar ao Login</b-button
            >
            <b-button
              :size="$mq == 'sm' ? 'is-small' : ''"
              v-show="showRegister"
              type="is-success"
              @click="validForm()"
              >Confirmar</b-button
            >
          </div>
        </div>
      </footer>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import vSelect from "vue-select";
import Payment from "@/components/payment/Payment";
import mask from "vue-r-mask";

import { ValidarCPF } from "@/shared/Utils";

export default {
  name: "Login",
  components: {
    "v-select": vSelect,
    payment: Payment,
  },
  directives: {
    mask: mask,
  },
  props: {
    isModal: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      action: "",
      influencer: "",
      cupom: "",
      forgotModal: false,
      activeStep: null,
      forgot: {
        email: "",
        config: {},
      },
      showLogin: true,
      showRegister: false,
      showSignature: false,
      showformError: false,
      auth: {
        email: "",
        pwd: "",
      },
      cadUser: {
        id: 0,
        nome: "",
        sobrenome: "",
        nome_completo: "",
        email: "",
        pass: "",
        cpf: "",
        token: "",
        endereco: {
          logradouro: "",
          numero: "",
          comp: "",
          bairro: "",
          cidade: "",
          cep: "",
          uf: "",
          pais: "Brasil",
        },
        plano: "",
        planoDescricao: "",
        planoValor: 0,
        referencia: "",
        cupom: "",
      },
      states: [
        "AC",
        "AL",
        "AM",
        "AP",
        "BA",
        "CE",
        "DF",
        "ES",
        "GO",
        "MA",
        "MT",
        "MS",
        "MG",
        "PA",
        "PB",
        "PR",
        "PE",
        "PI",
        "RJ",
        "RN",
        "RO",
        "RS",
        "RR",
        "SC",
        "SE",
        "SP",
        "TO",
      ],
      getNet: {},
      selectedPlan: {},
    };
  },
  computed: {
    ...mapGetters(["loggedIn", "userInfo", "userId", "userValid", "token"]),
  },
  mounted() {
    if (this.$route.query.f) {
      this.$buefy.dialog.alert({
        title: "ATENÇÃO !",
        message:
          "Você receberá um <b>email</b> com as instruções para alterar sua senha!",
        type: "is-success",
        hasIcon: true,
      });
    }

    if (this.$route.query.action) {
      this.action = this.$route.query.action;
      this.showLogin = this.action.indexOf("login") !== -1 ? true : false;
      this.showRegister = this.action.indexOf("cad") !== -1 ? true : false;
    }
    if (this.$route.query.cupom) {
      this.cupom = this.$route.query.cupom;
    }
    if (this.$route.query.influencer) {
      this.influencer = this.$route.query.influencer;
    }
  },
  methods: {
    ...mapActions([
      "authLogin",
      "setLogin",
      "addUser",
      "updateUser",
      "getValidUser",
      "updateSignature",
    ]),
    showRegisterVodal: async function (form) {
      this.showLogin = false;
      this.showRegister = false;
      this.showSignature = false;

      switch (form) {
        case "login":
          this.showLogin = true;

          this.cadUser = {
            id: "",
            nome: "",
            sobrenome: "",
            nome_completo: "",
            email: "",
            pass: "",
            cpf: "",
            token: "",
            endereco: {
              logradouro: "",
              numero: "",
              comp: "",
              bairro: "",
              cidade: "",
              cep: "",
              uf: "",
              pais: "Brasil",
            },
            plano: "",
            planoDescricao: "",
            planoValor: 0,
            referencia: "",
            cupom: "",
          };

          break;

        case "register":
          this.showRegister = true;
          break;

        case "signature":
          let loader = this.$loading.show();

          try {
            await this.register();
            if (this.loggedIn) {
              this.cadUser.plano = "";
              this.cadUser.planoDescricao = "";
              this.cadUser.planoValor = 0;
              this.cadUser.cupom = "";
              this.cadUser.referencia = "";

              this.showSignature = true;
            }
            loader.hide();
          } catch (err) {
            loader.hide();
            console.error("ERROR_REGISTER_USER");
            console.error(err);

            this.$buefy.dialog.alert({
              title: "ATENÇÃO!",
              message:
                "Não foi possível realizar seu cadastro. Favor entrar em contato com o suporte enviando um e-mail para suporte10@escala10.com.br",
              type: "is-danger",
              hasIcon: true,
            });
          }
          break;

        default:
          this.showLogin = true;
          break;
      }

      return true;
    },
    authentication: async function () {
      let loader = this.$loading.show();

      try {
        await this.authLogin(this.auth);

        if (this.loggedIn) {
          if (this.isModal) {
            this.$emit("login", this.userInfo);
          } else if (this.action.indexOf("promotion") !== -1) {
            this.$router.push({
              path: "/renewSignature/" + this.userId + "?action=" + this.action,
            });
          } else if (this.action.indexOf("renovacao") !== -1) {
            this.$router.push({
              path:
                "/renewSignature/" +
                this.userId +
                "?action=" +
                this.action +
                "&influencer=" +
                this.influencer +
                "&cupom=" +
                this.cupom,
            });
          } else if (this.action.indexOf("influencer") !== -1) {
            if (
              this.userInfo.planoValor == 0 ||
              this.userInfo.planoValor == null
            ) {
              this.$buefy.dialog.confirm({
                title: "ATENÇÃO !!!",
                message: `<p>Identificamos que está cadastrado em um plano gratuito!</p><p><b>Deseja realizar um upgrade para ter acesso a mais informações e aumentar suas chances de mitar?</b></p>`,
                confirmText: "Fazer Upgrade",
                cancelText: "Manter Gratuito",
                type: "is-info",
                hasIcon: true,
                onConfirm: async () => {
                  this.$router.push({
                    path:
                      "/renewSignature/" +
                      this.userId +
                      "?action=" +
                      this.action +
                      "&influencer=" +
                      this.influencer +
                      "&cupom=" +
                      this.cupom,
                  });
                },
                onCancel: async () => {
                  this.$router.push({ path: "/painel/ligas" });
                },
              });
            } else {
              this.$router.push({ path: "/painel/ligas" });
            }
          } else {
            this.$router.push({ path: "/painel/ligas" });
          }
        }

        loader.hide();
      } catch (err) {
        console.log(err);

        loader.hide();

        this.$buefy.dialog.confirm({
          title: "Atenção!",
          message: `Não foi possível realizar seu login, tente novamente ou clique em "Esqueci a Senha" abaixo. Caso o problema persista favor entrar em contato com o suporte enviando um e-mail para suporte10@escala10.com.br`,
          type: "is-danger",
          hasIcon: true,
          confirmText: "Esqueci a Senha",
          cancelText: "Fechar",
          onConfirm: (value) => {
            this.openForgotModal();
          },
        });

        // resetando campos do login
        this.auth.email = "";
        this.auth.pwd = "";
      }
    },
    formatField: async function (field) {
      switch (field) {
        case "auth.email":
          this.auth.email = this.auth.email.toLowerCase();
          this.auth.email = this.auth.email.replace(/ /g, "");
          break;

        case "cadUser.email":
          this.cadUser.email = this.cadUser.email.toLowerCase();
          this.cadUser.email = this.cadUser.email.replace(/ /g, "");
          break;
      }
    },
    validForm: async function (field) {
      let valid = true;

      switch (field) {
        case "nome":
          this.cadUser.nome = this.cadUser.nome ? this.cadUser.nome.trim() : "";
          valid = this.$refs.nome.isValid;
          break;
        case "sobrenome":
          this.cadUser.sobrenome = this.cadUser.sobrenome
            ? this.cadUser.sobrenome.trim()
            : "";
          valid = this.$refs.sobrenome.isValid;
          break;

        case "cpf":
          this.cadUser.cpf = this.cadUser.cpf ? this.cadUser.cpf.trim() : "";

          if (await !ValidarCPF(this.cadUser.cpf)) {
            valid = false;

            this.$buefy.dialog.alert({
              title: "ATENÇÃO!",
              message: "CPF inválido.",
              type: "is-warning",
              hasIcon: true,
            });
          } else {
            await this.getValidUser(this.cadUser);

            if (this.userValid) {
              if (
                !this.userValid.available &&
                this.userValid.id != this.cadUser.id
              ) {
                let msg = "";
                valid = false;
                msg = "CPF já cadastrado em nosso site.";
                msg +=
                  "<br>* Favor realizar o login para ter acesso ao site. Caso não lembre suas credenciais, execute o processo de recuperação de senha através do link 'Esqueci minha senha' para que possamos ajudá-lo a recupera-la.";
                msg +=
                  "<br>* Caso tenha expirado seu acesso, favor realizar o login para que possa efetuar a renovação do seu plano de acesso.";

                this.$buefy.dialog.alert({
                  title: "ATENÇÃO!",
                  message: msg,
                  type: "is-warning",
                  hasIcon: true,
                });
              }
            }
          }
          if (valid) valid = this.$refs.cpf.isValid;
          break;

        case "telefone":
          this.cadUser.telefone = this.cadUser.telefone
            ? this.cadUser.telefone.trim()
            : "";
          valid = this.$refs.telefone.isValid;
          break;

        case "email":
          await this.getValidUser(this.cadUser);

          this.cadUser.email = this.cadUser.email
            ? this.cadUser.email.trim()
            : "";

          if (this.userValid) {
            if (
              !this.userValid.available &&
              this.userValid.id != this.cadUser.id
            ) {
              let msg = "";
              valid = false;
              msg = "E-Mail já cadastrado em nosso site.";
              msg +=
                "<br>* Favor realizar o login para ter acesso ao site. Caso não lembre suas credenciais, execute o processo de recuperação de senha através do link 'Esqueci minha senha' para que possamos ajudá-lo a recupera-la.";
              msg +=
                "<br>* Caso tenha expirado seu acesso, favor realizar o login para que possa efetuar a renovação do seu plano de acesso.";

              this.$buefy.dialog.alert({
                title: "ATENÇÃO!",
                message: msg,
                type: "is-warning",
                hasIcon: true,
              });
            }
          }
          if (valid) valid = this.$refs.email.isValid;
          break;

        case "cidade":
          this.cadUser.endereco.cidade = this.cadUser.endereco
            ? this.cadUser.endereco.cidade
              ? this.cadUser.endereco.cidade.trim()
              : ""
            : "";
          valid = this.$refs.cidade.isValid;
          break;

        case "pais":
          this.cadUser.endereco.pais = this.cadUser.endereco
            ? this.cadUser.endereco.pais
              ? this.cadUser.endereco.pais.trim()
              : ""
            : "";
          valid = this.$refs.pais.isValid;
          break;

        case "plan":
          if (this.cadUser.plano === "") {
            valid = false;

            this.$buefy.dialog.alert({
              title: "ATENÇÃO!",
              message: "Favor selecionar um plano para confirmar a assinatura!",
              type: "is-warning",
              hasIcon: true,
            });
          }
          break;

        default:
          this.cadUser.nome = this.cadUser.nome ? this.cadUser.nome.trim() : "";
          this.cadUser.sobrenome = this.cadUser.sobrenome
            ? this.cadUser.sobrenome.trim()
            : "";
          this.cadUser.cpf = this.cadUser.cpf ? this.cadUser.cpf.trim() : "";
          this.cadUser.telefone = this.cadUser.telefone
            ? this.cadUser.telefone.trim()
            : "";
          this.cadUser.email = this.cadUser.email
            ? this.cadUser.email.trim()
            : "";
          this.cadUser.endereco.cidade = this.cadUser.endereco
            ? this.cadUser.endereco.cidade
              ? this.cadUser.endereco.cidade.trim()
              : ""
            : "";
          this.cadUser.endereco.uf = this.cadUser.endereco
            ? this.cadUser.endereco.uf
              ? this.cadUser.endereco.uf.trim()
              : ""
            : "";
          this.cadUser.endereco.pais = this.cadUser.endereco
            ? this.cadUser.endereco.pais
              ? this.cadUser.endereco.pais.trim()
              : ""
            : "";

          this.$refs.nome.checkHtml5Validity();
          this.$refs.sobrenome.checkHtml5Validity();
          this.$refs.cpf.checkHtml5Validity();
          this.$refs.telefone.checkHtml5Validity();
          this.$refs.email.checkHtml5Validity();
          this.$refs.senha.checkHtml5Validity();
          this.$refs.cidade.checkHtml5Validity();
          this.$refs.pais.checkHtml5Validity();

          let msgAlert = "";

          if (!this.$refs.nome.isValid) {
            msgAlert += (msgAlert !== "" ? ", " : "") + "Nome";
          }
          if (!this.$refs.sobrenome.isValid) {
            msgAlert += (msgAlert !== "" ? ", " : "") + "Sobrenome";
          }
          if (!this.$refs.cpf.isValid) {
            msgAlert += (msgAlert !== "" ? ", " : "") + "CPF";
          }
          if (!this.$refs.telefone.isValid) {
            msgAlert += (msgAlert !== "" ? ", " : "") + "Telefone";
          }
          if (!this.$refs.email.isValid) {
            msgAlert += (msgAlert !== "" ? ", " : "") + "E-Mail";
          }
          if (!this.$refs.senha.isValid) {
            msgAlert += (msgAlert !== "" ? ", " : "") + "Senha";
          }
          if (!this.$refs.cidade.isValid) {
            msgAlert += (msgAlert !== "" ? ", " : "") + "Cidade";
          }
          if (!this.$refs.uf.isValid) {
            msgAlert += (msgAlert !== "" ? ", " : "") + "UF";
          }
          if (!this.$refs.pais.isValid) {
            msgAlert += (msgAlert !== "" ? ", " : "") + "País";
          }
          if (msgAlert !== "") {
            msgAlert = "* Campo(s) com dados inconsistentes: " + msgAlert;
          }

          if (await !ValidarCPF(this.cadUser.cpf)) {
            msgAlert += (msgAlert !== "" ? "<br>" : "") + "* CPF inválido.";
          } else {
            await this.getValidUser(this.cadUser);

            if (this.userValid) {
              if (
                !this.userValid.available &&
                this.userValid.id !== this.cadUser.id
              ) {
                msgAlert +=
                  (msgAlert !== "" ? "<br>" : "") +
                  "* CPF / E-Mail já cadastrados em nosso site.";
                msgAlert +=
                  (msgAlert !== "" ? "<br>" : "") +
                  "* Favor realizar o login com este e-mail para ter acesso ao site. Caso não lembre sua senha, favor clicar no link 'Esqueci minha senha' para que possamos ajudá-lo a recupera-la.";
                msgAlert +=
                  (msgAlert !== "" ? "<br>" : "") +
                  "* Caso tenha expirado seu acesso, favor realizar o login para que possa efetuar a renovação do seu plano de acesso.";
              }
            }
          }

          if (msgAlert !== "") {
            valid = false;

            this.$buefy.dialog.alert({
              title: "ATENÇÃO!",
              message: msgAlert,
              type: "is-warning",
              hasIcon: true,
            });
          } else {
            this.showRegisterVodal("signature");
          }

          break;
      }

      return valid;
    },
    openForgotModal() {
      this.$buefy.dialog.prompt({
        type: "is-primary",
        message: `Insira seu e-mail abaixo para enviar as instruções.`,
        title: "Instruções",
        confirmText: "Enviar",
        onConfirm: (value) => {
          this.$http
            .post(url + "/usuarios/forgotPass", { email: value })
            .then((res) => {
              if (res.body.status == "error") {
                this.$buefy.dialog.alert({
                  title: "Erro ao enviar e-mail",
                  message:
                    "Não foi possível enviar as instruções. Verifique seu e-mail e tente novamente!",
                  type: "is-danger",
                  hasIcon: true,
                  icon: "times-circle",
                  iconPack: "fa",
                });
              } else {
                this.$toasted.show("Email enviado!", {
                  position: "top-center",
                  duration: 3000,
                  type: "success",
                  className: "toasted-class",
                });
              }
            })
            .catch((err) => {
              this.$buefy.dialog.alert({
                title: "Erro ao enviar e-mail",
                message:
                  "E-mail não encontrado. Verifique seu e-mail e tente novamente!",
                type: "is-danger",
                hasIcon: true,
                icon: "times-circle",
                iconPack: "fa",
              });
            });
        },
      });
    },
    register: async function () {
      try {
        if (this.loggedIn) {
          let error = await this.updateUser(this.cadUser);

          let msgError = "";
          if (error && error.length > 0) {
            let msgError = response.join("<br>");
          }

          if (msgError !== "") {
            this.$toasted.show(msgError, {
              position: "top-center",
              duration: 3000,
              type: "is-danger",
              className: "toasted-class",
            });
          }
        } else {
          await this.addUser(this.cadUser);
        }

        if (this.loggedIn) {
          this.cadUser.id = this.userInfo.id;
          this.cadUser.token = this.userInfo.token;

          if (this.isModal) {
            this.$emit("login", this.userInfo);
          }
        } else {
          this.$buefy.dialog.alert({
            title: "ATENÇÃO!",
            message: "Não foi possível realizar o cadastro. Tente novamente!",
            type: "is-danger",
            hasIcon: true,
          });
        }
      } catch (err) {
        console.error("ERROR_REGISTER");
        console.error(err);

        this.$buefy.dialog.alert({
          title: "ATENÇÃO!",
          message: "Não foi possível realizar o cadastro. Tente novamente!",
          type: "is-danger",
          hasIcon: true,
        });
      }
    },
  },
};
</script>

<style>
#login-2 {
  height: 100%;
  width: 100%;
  overflow-y: auto;
}
.lg-container {
  position: relative;
  padding: 5px;
  background-color: #3c3c3c;
  border-radius: 7px;
}
.lg-button {
  position: fixed;
  padding: 25px 0;
  background-color: #1a1a1a;
  border-top: 5px solid #9417fa;
  bottom: 0;
  left: 20.6%;
  width: calc(60% - 1.74rem);
  z-index: 2;
}
.lg-content {
  padding: 1rem;
  border-radius: 0 0 7px 7px;
}
.itens-tab {
  padding: 20px 0 30px 0;
}
.light {
  background-color: #4d4d4d;
}
@media (min-width: 320px) and (max-width: 1024px) {
  #login-2 {
    background-color: #2e2e2e;
  }
  .lg-button {
    width: calc(100% - 3px) !important;
    left: 0 !important;
  }
  .lg-container {
    background-color: #2e2e2e !important;
  }
}
.align-content-login {
  height: 100%;
}

@media (min-width: 320px) and (max-width: 1024px) {
  .container,
  .container-register,
  .container-signature {
    width: calc(100% - 10px) !important;
  }
  .container {
    padding: 0 !important;
  }
  .container-login {
    width: calc(100% - 30px) !important;
  }
  .full-content .form-data {
    width: calc(100% - 40px) !important;
  }
  .half-content .form-data {
    width: calc(100% - 40px) !important;
  }
  .three-content .form-data {
    width: calc(100% - 40px) !important;
  }

  .container-signature {
    padding-bottom: 100px !important;
  }
  .container-signature .buttons {
    width: 100% !important;
    right: 0 !important;
    bottom: 0 !important;
    padding: 5px 0;
    background-color: black;
  }

  .plan-signature {
    width: calc(100% - 40px) !important;
  }

  .contato {
    width: 90% !important;
  }
}

#login {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  background-image: url("../assets/bg_escala10.png");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}
.title-2 {
  font-size: 2.5em;
  font-weight: 400;
  text-align: center;
  color: white;
  margin-bottom: 0;
}
.sub-title {
  font-size: 1em;
  color: white;
  margin-top: 10px;
  text-align: center;
}

.container-login,
.container-register,
.container-signature {
  width: -webkit-fill-available;
  height: auto;
  padding: 5px;
  transition: 0.2s ease-in-out;
  z-index: 999;
}
.container-login {
  width: 30%;
  height: 100%;
}

.contato {
  color: white;
  font-size: 0.9em;
  width: 100%;
  padding: 10px;
}
.container-signature .buttons .bt-confirm {
  border-radius: 50px;
  padding: 20px 50px !important;
}
.pdr-15 {
  padding-right: 20px;
}

.full-content,
.half-content,
.three-content {
  width: 100%;
}
.full-content .form-data,
.half-content .form-data,
.three-content .form-data {
  display: inline-table;
  margin: 0px 20px;
  text-align: left;
}
.full-content .form-data {
  width: calc(100% - 40px);
}
.half-content .form-data {
  width: calc(49% - 40px);
}
.three-content .form-data {
  width: calc(33% - 40px);
}

.warning {
  color: yellow /*#f0c349*/;
}
.danger {
  color: red /*#f04949*/;
}
.success {
  color: green /*#78f049*/;
}

.container-login h1 {
  color: white;
}

.container-signature .full-content {
  text-align: center;
}

.plan-signature {
  display: inline-block;
  cursor: pointer;
  margin: 20px;
  width: calc(30% - 40px);
  height: 350px;
  color: white;
  font-size: 1.5em;
  text-align: center;
  align-content: center;
  vertical-align: top;
  border-radius: 50px;
  background-color: rgba(0, 0, 0, 0.5);
  transform: scale(0.9, 0.9);
  transition: all 0.2s ease-in-out;
}
.plan-signature:hover {
  transform: scale(1, 1);
}
.plan-selected {
  transform: scale(1, 1);
  box-shadow: 0px 0px 10px 15px rgba(0, 255, 247, 1);
}
.plan-blocked {
  background-color: rgba(0, 0, 0, 0.2);
}
.saleSignature {
  height: 450px;
}
.blockSignature {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  font-size: 2em;
  font-weight: bolder;
  color: black;
  border-radius: 50px;
  background-color: rgba(0, 255, 247, 0.5);
}
.plan-title {
  width: auto;
  color: black;
  padding: 10px 0px;
  font-size: 1.3em;
  font-weight: bold;
  box-shadow: 0px 7px 15px #00fffd;
  border-radius: 40px 40px 0 0;
  background-color: #00fffd;
}
.plan-subtitle {
  width: auto;
  color: black;
  padding: 5px;
  margin: 30px 10px 0 10px;
  font-size: 0.6em;
  font-weight: bold;
  border-radius: 20px;
  background-color: #00fffd;
}
.plan-option {
  display: none;
}
.plan-description {
  font-size: 1.5em;
  font-weight: bold;
  margin: 5px;
}
.plan-obs {
  font-size: 0.5em;
}
.plan-value {
  font-size: 1.5em;
  font-weight: bold;
  margin: 10px;
}

.plan-cupons {
  width: auto;
}
.plan-cupom {
  position: relative;
  width: fit-content;
}
.cupom-desconto {
  width: fit-content;
  color: black;
  padding: 5px 20px;
  border-radius: 20px;
  background-color: #00fffd;
  z-index: 999;
}
.cupom-desconto .percentual-valor {
  font-size: 1.2em;
  font-weight: bold;
}
.cupom-desconto .percentual-texto {
  font-size: 0.4em;
  font-weight: bold;
}
.cupom-valor {
  position: relative;
  top: -30px;
  color: #00fffd;
  padding: 50px 20px;
  border-radius: 20px;
  background-color: #4d4d4d;
}
.cupom-valor .valor {
  font-size: 1.5em;
  font-weight: bolder;
}
.cupom-valor .descricao {
  font-size: 0.7em;
}
.cupom-valor .moeda {
  font-size: 0.5em;
}

.form-data.cupom {
  text-align: center;
}
.cupom label,
.cupom input,
.cupom button {
  width: min-content;
  margin: 10px;
}
</style>
